<template lang="html">
  <div class="list-numbers" v-if="initialData.list">
    <div
      class="list-numbers__item"
      v-for="(item, i) in initialData.list"
      :key="i"
    >
      <div class="list-numbers__item-number">{{ i + 1 }}</div>
      <div class="list-numbers__item-text" v-html="item.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListNumbers',
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.list-numbers {
  &__item {
    &:not(:last-child) {
      margin-bottom: $sectionMediumMargin;
    }

    &-text {
      @include tcl-big-text;
      margin-top: -30px;
    }

    &-number {
      font-size: 90px;
      line-height: 1;
      font-weight: 600;
      opacity: 0.5;
    }
  }
}
</style>
